import React from 'react';
import SEO from '../components/seo';
import Header from '../components/header'
import Hero from '../components/Hero'
import Layout from '../components/layout'
import Container from '../components/Container'

const Success = () => (
    <Layout>
        <SEO title='Success' /> 
        <Header />
        <Hero 
        title="Thanks for your submission!"
        subtitle="We will review your submission and be in touch shortly."
         />
        <Container as="main" id='main-content'>
           
        </Container>
    </Layout>
)

export default Success;